<template>
    <div class="d-flex flex-column">
        <div class="d-flex">
            <div class="maxW-150 mr-4">
                <v-text-field 
                    v-model="settings.maxWidth"
                    label="Макс. ширина холста"
                    hint="Если не указана и есть фоновое изображение, ширина подстроится автоматически"
                    persistent-hint
                    type="number"
                    
                    clearable
                    @click:clear="settings.maxWidth = null"
                />
            </div>
            <div class="maxW-150 mr-4">
                <v-select
                    v-model="settings.draggablesPosition"
                    :items="positionsList"
                    label="Расположить элементы"
                />
            </div>
            <v-checkbox
                v-model="settings.clone"
                label="Клонирование элементов при перемещении"
                @change="removeClones"
            ></v-checkbox>
            <v-checkbox
                v-model="settings.shuffle"
                label="Перетасовка элементов на старте"
            ></v-checkbox>
        </div>
        <div class="d-flex">
            <v-checkbox
                v-model="settings.rotatable"
                label="Доступен поворот"
                hint="Только для элементов-изображений"
                persistent-hint
                :disabled="!draggablesAsImage"
                class="mr-4"
            ></v-checkbox>
            <v-text-field 
                v-model="settings.rotationCenter.left"
                label="Поворотная точка опоры по горизонтали (%)"
                type="number"
                clearable
                class="mr-4"
                :disabled="!settings.rotatable"
                @click:clear="setValue('settings.rotationCenter.left', 50)"
            />
            <v-text-field 
                v-model="settings.rotationCenter.top"
                label="Поворотная точка опоры по вертикали (%)"
                type="number"
                clearable
                :disabled="!settings.rotatable"
                @click:clear.prevent="setValue('settings.rotationCenter.top', 50)"
            />
        </div>
        <div class="d-flex">
            <v-slider
                v-model="settings.rotateButtonX"
                label="Позиция кнопки вращения по горизонтали (%)"
                :disabled="!settings.rotatable"
                dense
                thumb-label="always"
                max="90"
                min="10"
                class="my-5"
            />
        </div>

        <div class="d-flex">
            <v-slider
                v-model="settings.intersect"
                label="Чувствительность (%)"
                hint="Чувствительность холста к перемещаемым элементам. 0 - холст не проводит проверку на попадание. 100 - требуется полное вхождение."
                persistent-hint
                dense
                thumb-label="always"
                max="100"
                min="0"
                class="my-5"
            />
        </div>
        
        <media-library
            v-if="model"
            v-model="settings.backgroundImage.src"
            :path="`${model.year}/${transliterate(model.subject.toLowerCase())}/${model.grade}`"
            label="Фоновое изображение"
            class="mt-5"
        />
        <v-alert v-else type="error" class="my-2">
            Возможность использования медиа-библиотеки заблокирована для несохранённой формы.
            Сохраните форму и обновите страницу.
        </v-alert>

        <div class="d-flex mt-5">
            <v-text-field 
                v-model="settings.draggablesSize.width"
                label="Ширина перемещаемого элемента (px)"
                type="number"
                hint="Пустое значение активирует автоматическую адаптацию ширины"
                persistent-hint
                clearable
                class="mr-4"
            />
            <v-text-field 
                v-model="settings.draggablesSize.height"
                label="Высота перемещаемого элемента (px)"
                hint="Пустое значение активирует автоматическую адаптацию высоты"
                persistent-hint
                type="number"
                clearable
            />
        </div>
        
        <v-switch
            v-model="draggablesAsImage"
            :color="$const.color.primary"
            :label="`Тип элементов: ${draggablesAsImage ? 'Изображения' : 'Текст'}`"
            @change="changeDraggablesType"
        ></v-switch>
        
        <v-card>
            <v-card-text>
                <p class="text--primary text-h6">Перемещаемые элементы</p>
                <div
                    v-for="(item, i) in previewItems"
                    :key="i"
                    class="d-flex flex-row align-center mb-5"
                >
                    <v-text-field
                        v-if="!draggablesAsImage"
                        v-model="item.text"
                        label="Введите текст"
                        :color="$const.color.primary"
                        class="mr-4"
                        @input="updateCorrectAnswer"
                    />
                    <media-library
                        v-else
                        v-model="item.src"
                        :path="`${model.year}/${transliterate(model.subject.toLowerCase())}/${model.grade}`"
                        label="Изображение"
                        class="mr-4"
                        @input="updateCorrectAnswer"
                    />
                    <v-checkbox
                        v-if="settings.clone"
                        v-model="item.isClone"
                        label="Клон"
                        @change="onIsCloneUpdate"
                    ></v-checkbox>
                    <v-icon
                        class="c-pointer ml-5 "
                        style="height: 25px; width: 25px;"
                        @click="itemRemove(i)"
                    >mdi-trash-can-outline</v-icon>
                </div>
                <v-icon @click="itemAdd">mdi-plus-circle-outline</v-icon>
            </v-card-text>
        </v-card>
        <div class="mt-5">
            <v-btn @click="openPreview">Настроить позиции правильного ответа</v-btn>
        </div>
        <v-dialog v-model="showDialog" persistent max-width="872px">
            <v-card>
                <v-toolbar dark :color="$const.color.primary">
                    <v-toolbar-title>Настройка примера правильного ответа</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-container>
                        <app-drag-n-drop
                            v-if="showDialog"
                            ref="dnd-preview"
                            :settings="{...settings, clone: false}"
                            :value="previewItems"
                            class="ff-inter-regular"
                            style="font-size: 19px;"
                            @update="onPreviewUpdate"
                        />
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn outlined color="warning" @click.prevent.stop="resetPreview">
                        Сбросить и закрыть
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn outlined color="warning" @click.prevent.stop="closePreview">
                        Закрыть
                    </v-btn>
                    <v-btn :color="$const.color.primary" dark @click.prevent.stop="onPreviewSave">
                        Применить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import MediaLibrary from '@/components/inputs/media-library'
import StringHelper from '@/plugins/string'
import TaskViewerLib from '@/libraries/task-viewer/index.js'

export default {
    mixins: [interactiveMixin],
    components: { MediaLibrary, AppDragNDrop: TaskViewerLib.DragNDrop },
    inject: ['model'],
    data () {
        return {
            previewItems: [],
            previewPayload: null,
            correctAnswer: [],
            settings: {
                maxWidth: 500,
                rotatable: false,
                rotationCenter: { left: 50, top: 50 }, // Смещение центра поворота в процентном соотношении.
                backgroundImage: { src: null }, // { src: String }
                draggablesSize: { width: 100, height: 100 },
                clone: false,
                intersect: 100,
                rotateButtonX: 90,
                shuffle: false, // Перетасовка элементов на старте
                draggablesPosition: 'bottom', // 'bottom' || 'right',
            },
            positionsList: [
                { text: 'Снизу', value: 'bottom' },
                { text: 'Справа', value: 'right' }
            ],
            draggablesAsImage: false,
            showDialog: false
        }
    },
    created () {
        if (this.correctAnswer?.length && this.correctAnswer[0]?.src) {
            this.draggablesAsImage = true
        }
        this.previewItems = _.cloneDeep(this.correctAnswer).map(item => {
            if (this.settings.clone && item.cloneFrom !== undefined) {
                item.isClone = true
            }
            return item
        })
    },
    computed: {
        transliterate () { return StringHelper.transliterate }
    },
    methods: {
        setValue (field, value) {
            setTimeout(() => {
                _.set(this, field, value)
            })
        },
        changeDraggablesType () {
            this.previewItems = []
            this.updateCorrectAnswer()
        },
        itemAdd () {
            const newItem = !this.draggablesAsImage ? ({ text: '', isClone: false }) : ({ src: null, isClone: false })
            newItem.initialPos = true
            this.previewItems.push(newItem)
            this.updateCorrectAnswer()
        },
        itemRemove (index) {
            this.previewItems.splice(index, 1)
            this.updateCorrectAnswer()
        },
        openPreview () {
            this.showDialog = true
            setTimeout(() => {
                window.dispatchEvent(new Event('scroll'))
                setTimeout(() => {
                    this.$refs['dnd-preview']?.updateDraggablesGlobalPosition?.()
                    setTimeout(() => {
                        this.$refs['dnd-preview']?.updateDraggablesGlobalPosition?.()
                    }, 100)
                }, 200)
            }, 200)
        },
        closePreview () {
            this.previewPayload = null
            this.showDialog = false
        },
        onPreviewUpdate (payload) {
            this.previewPayload = payload
        },
        onPreviewSave () {
            if (this.previewPayload) {
                this.previewPayload.value.forEach((item, index) => {
                    const cItem = this.previewItems[index]
                    if (cItem) {
                        cItem.initialPos = item.initialPos
                        cItem.inContainerId = parseFloat(item.inContainerId)
                        
                        cItem.relativeLeft = parseFloat(item.relativeLeft)
                        cItem.relativeTop = parseFloat(item.relativeTop)
                    }
                })
                this.updateCorrectAnswer()
            }
            this.closePreview()
        },
        updateCorrectAnswer () {
            this.correctAnswer = _.cloneDeep(this.previewItems).map(item => {
                item.cloneFrom = item.isClone ? 1 : undefined
                return item
            })
        },
        resetPreview () {
            this.previewItems.forEach(cItem => {
                cItem.initialPos = true
                cItem.inContainerId = null
                cItem.relativeLeft = 0
                cItem.relativeTop = 0
            })
            this.closePreview()
        },
        removeClones () {
            this.previewItems = this.previewItems.filter(item => !item.isClone)
            this.updateCorrectAnswer()
        },
        onIsCloneUpdate () {
            this.updateCorrectAnswer()
        }
    }
}
</script>